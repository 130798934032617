<template>
  <div>
    <div class="position-relative">
      <v-skeleton-loader
        type="image"
        height="360"
        class="loader"
        v-if="loading"
      />
      <swiper :options="carouselOption" class="" v-else>
        <swiper-slide
          v-for="(banner, i) in shopDetails.banners"
          :key="i"
          class="lh-0"
        >
          <img
            :src="addResourceImage(banner)"
            class="h-150px h-md-360px img-fit"
            :alt="shopDetails.name"
            @error="imageFallback($event)"
          />
        </swiper-slide>
      </swiper>
      <div class="shop-info w-100" v-if="!loading">
        <v-container class="d-md-flex">
          <div
            class="pa-3 pa-md-5 position-relative mb-md-5 flex-fill d-flex"
            style="margin-right: 1px"
          >
            <div class="white opacity-80 absolute-full"></div>
            <div class="d-md-flex position-relative align-center minw-0">
              <img
                :src="addResourceImage(shopDetails.logo)"
                :alt="shopDetails.name"
                class="h-90px"
                @error="imageFallback($event)"
              />
              <div class="ms-md-4 minw-0">
                <h1 class="fs-21">{{ shopDetails.name }}</h1>
                <div class="fs-12 opacity-80 text-truncate">
                  <span
                    v-for="(category, i) in shopDetails.categories?.data"
                    :key="i"
                  >
                    {{ category.name }}
                    <span v-if="shopDetails.categories?.data.length - i != 1">
                      ,
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="pa-3 pa-md-5 position-relative mb-md-5">
            <div class="white opacity-80 absolute-full"></div>
            <div class="position-relative">
              <div>
                {{ $t('ratings') }}
              </div>
              <div class="d-flex my-2">
                <span class="me-2">{{ shopDetails.rating.toFixed(2) }}</span>
                <v-rating
                  class="lh-1-5"
                  background-color=""
                  empty-icon="las la-star"
                  full-icon="las la-star active"
                  half-icon="las la-star half"
                  hover
                  half-increments
                  readonly
                  size="12"
                  length="5"
                  :value="shopDetails.rating"
                ></v-rating>

                <span class="me-2 opacity-80">
                  ({{ shopDetails.reviews_count }} {{ $t('ratings') }})
                </span>
              </div>
              <span class="mr-12 red--text fs-12" v-if="showContern">
                {{ shopDetails.followers }}{{ $t('focus_num') }}
              </span>
              <!-- done: 隐藏店铺收藏 -->
              <!-- <template v-if="isThisFollowed(shopDetails.id)">
                <v-btn
                  elevation="0"
                  color="grey"
                  @click="removeFromFollowedShop(shopDetails.id)"
                  class="white--text darken-1"
                >
                  {{ $t("unfollow") }}
                </v-btn>
              </template> -->
              <!-- <template v-else>
                <v-btn
                  elevation="0"
                  color="primary"
                  @click="addNewFollowedShop(shopDetails.id)"
                >
                  {{ $t("follow") }}
                </v-btn>
              </template> -->
            </div>
          </div>
        </v-container>
      </div>
    </div>
    <div class="grey darken-4 py-2">
      <v-container class="py-1">
        <v-list-item-group class="d-flex" active-class="" dark>
          <v-list-item
            class="flex-grow-0 flex-fill px-1 me-12 border-bottom border-2 border-transparent"
            active-class="white--text border-primary"
            :to="{ name: 'ShopDetails', params: { slug: $route.params.slug } }"
            exact
          >
            {{ $t('store') }}
          </v-list-item>
          <!-- 去除优惠券 -->
          <!-- <v-list-item
            class="flex-grow-0 flex-fill px-1 me-12 border-bottom border-2 border-transparent"
            active-class="white--text border-primary"
            :to="{ name: 'ShopCoupons', params: { slug: $route.params.slug } }"
          >
            {{ $t("coupons") }}
          </v-list-item> -->
          <v-list-item
            class="flex-grow-0 flex-fill px-1 border-bottom border-2 border-transparent"
            active-class="white--text border-primary"
            :to="{ name: 'ShopProducts', params: { slug: $route.params.slug } }"
          >
            {{ $t('all_products') }}
          </v-list-item>

          <v-list-item
            v-if="showContern"
            active-class="white--text"
            class="flex justify-end flex-fill px-1 border-bottom border-2 border-transparent"
          >
            <span class="mr-12 red--text fs-12 hidden-xs-only">
              {{ shopDetails.followers }}{{ $t('focus_num') }}
            </span>
            <div>
              <div
                v-if="!shopDetails.is_follow"
                class="d-lg-block border-button"
                @click="clickFocus"
              >
                {{ $t('focus_on_the_store') }}
              </div>
              <div v-if="shopDetails.is_follow" @click="cancelFocus">
                {{ $t('has_focus_on') }}
              </div>
            </div>
          </v-list-item>
        </v-list-item-group>
      </v-container>
    </div>
    <div>
      <router-view :key="$route.path"></router-view>
    </div>
    <div class="dialog" v-if="isDialogVisible">
      <div>
        <h3>{{ isDialogTitle }}</h3>
        <v-btn
          x-large
          class="px-12 mb-4 w-50"
          elevation="0"
          color="primary"
          @click="$router.back()"
        >
          {{ $t('Return') }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  data: () => ({
    isDialogVisible: false,
    isDialogTitle: '',
    loading: true,
    shopDetails: {},
    carouselOption: {
      slidesPerView: 1
    },

    isFocusLoading: false
  }),
  computed: {
    ...mapGetters('follow', ['isThisFollowed']),
    ...mapGetters('app', ['showContern'])
  },
  methods: {
    ...mapActions('follow', ['addNewFollowedShop', 'removeFromFollowedShop']),
    ...mapActions('app', ['fetchShowConcern']),

    // 点击关注
    clickFocus() {
      if (this.isFocusLoading) return
      this.isFocusLoading = true

      this.call_api('post', 'user/follow/subscribe', {
        seller_user_id: this.shopDetails.seller_user_id
      })
        .then((res) => {
          console.log('e', res)
          this.isFocusLoading = false
          this.getDetail()
        })
        .catch(() => {
          this.isFocusLoading = false
        })
    },
    // 取消关注
    cancelFocus() {
      if (this.isFocusLoading) return
      this.isFocusLoading = true

      this.call_api('post', 'user/follow/unsubscribe', {
        seller_user_id: this.shopDetails.seller_user_id
      })
        .then((res) => {
          this.isFocusLoading = false
          this.getDetail()
        })
        .catch(() => {
          this.isFocusLoading = false
        })
    },

    async getDetail() {
      const res = await this.call_api('get', `shop/${this.$route.params.slug}`)

      console.log('shop details ::::::::::: ', res)

      if (res.data.success) {
        this.shopDetails = res.data.data
        this.loading = false
      } else {
        this.isDialogVisible = true
        this.isDialogTitle = res.data.message
        // this.snack({
        //   message: res.data.message,
        //   color: 'red',
        // })
        // this.$router.push({ name: '404' })
      }
    }
  },
  async created() {
    this.fetchShowConcern()
    this.getDetail()
  }
}
</script>
<style lang="scss" scoped>
@media (min-width: 960px) {
  .shop-info {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
  }
}

::v-deep .absolute-full {
  z-index: -1 !important;
}

.dialog {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1001;
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    width: 400px;
    height: 250px;
    background-color: #fff;
    text-align: center;

    h3 {
      margin-top: 20px;
      margin-bottom: 130px;
    }
  }
}

.border-button {
}
</style>
